import { styled, Box } from '@mui/material';

export const BackgroundBar = styled(Box)`
  background: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  right: 0;
  height: calc(100% + 16px);
  width: 40vw;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;
