import { AlertColor } from "@mui/material";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AlertComponent from "../components/AlertComponent";
import { TRANSLATIONS_PT } from "../translate/pt";

type AlertProps = {
  message: string;
  severity: AlertColor;
};

interface AlertContextData {
  AlertMessage: (
    severity: AlertColor,
    defaultMessage: string,
    response?: any
  ) => void;
  Alert: () => any;
}

type AlertContextProps = {
  children: ReactNode;
};

const AlertContext = createContext({} as AlertContextData);

const AlertProvider: React.FC<AlertContextProps> = ({ children }) => {
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const AlertMessage = useCallback(
    (severity: AlertColor, defaultMessage: string, response?: any) => {
      const translations: any = { ...TRANSLATIONS_PT };
      setOpen(true);
      if (!response) {
        return setAlert({ message: defaultMessage, severity });
      }

      if (typeof response.response.data.message === "string") {
        if (translations[response.response.data.message.replaceAll(" ", "")]) {
          return setAlert({
            message: response.response.data.message.replaceAll(" ", ""),
            severity,
          });
        }
        return setAlert({ message: defaultMessage, severity });
      }

      if (translations[response.response.data.message[0].replaceAll(" ", "")]) {
        return setAlert({
          message: response.response.data.message[0].replaceAll(" ", ""),
          severity,
        });
      }

      return setAlert({ message: defaultMessage, severity });
    },
    []
  );

  const Alert = useCallback(() => {
    if (alert) {
      return (
        <AlertComponent
          open={open}
          severity={alert.severity}
          message={t(alert.message)}
          setOpen={setOpen}
        />
      );
    }
  }, [alert, t, open]);

  const providerValue = useMemo(() => ({ AlertMessage, Alert }), [
    AlertMessage,
    Alert,
  ]);
  return (
    <AlertContext.Provider value={providerValue}>
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }

  return context;
};

export { useAlert, AlertProvider };
