import React, { useEffect } from "react";
import { useState } from "react";

import { Grid, Alert, Box } from "@mui/material";

import { FieldValues, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";

import { TextField } from "../../components/inputs/text-field/text-field.component";
import { Select } from "../../components/inputs/select/select.component";
import { PhoneInput } from "../../components/inputs/phoneInput/PhoneInput";

import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { Button } from "../../components/Button/Button";
import { UpdatePasswordModal } from "../../components/modal/updatePassword/UpdatePassword";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import { REGISTER_URL, VERIFY_USERNAME } from "../../services/global";
import { useParams } from "react-router";
import { useAlert } from "../../hooks/alert";

const validator = yup.object().shape({
  fullName: yup.string().required("nameRequired").min(5, "nameInvalid"),
  birthday: yup
    .date()
    .required("birthDateRequired")
    .max(new Date(), "birthDateFuture")
    .nullable(),
  phone: yup
    .string()
    .required("phoneRequired")
    .test(`test-password`, function (value) {
      const { path, createError } = this;
      return (
        isValidPhoneNumber(`${value}`) ||
        createError({ path, message: "phoneInvalid" })
      );
    }),
  username: yup
    .string()
    .required("usernameRequired")
    .matches(/^[a-zA-Z0-9._\-\b]+$/, "usernameInvalid"),
});

interface verifyUsernameResponse {
  msg: string;
  available: boolean;
  username: string;
}

export function EditProfile() {
  const { t } = useTranslation();
  const params: any = useParams();
  const { AlertMessage } = useAlert();

  const [loading, setLoading] = useState(false);
  const [usernameControl, setUsernameControl] = useState("");
  const [isAvailableUsername, setIsAvailableUsername] = useState(false);
  const debounced = useDebounce(usernameControl);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  async function handleUpdateUser(updatedUser: FieldValues) {
    setLoading(true);
    try {
      if (!isAvailableUsername || updatedUser.username !== usernameControl) {
        return;
      }
      const parsedPhone = parsePhoneNumber(updatedUser.phone);
      if (parsedPhone) {
        updatedUser.phoneDdi = parsedPhone.countryCallingCode;
        updatedUser.phone = parsedPhone.nationalNumber;
      }
      updatedUser.id = params.id;

      const { data } = await axios.post(REGISTER_URL, updatedUser);

      setLoading(false);
      localStorage.setItem(
        "user",
        Buffer.from(
          JSON.stringify({
            email: data.email,
            name: data.name,
          })
        ).toString("base64")
      );
      AlertMessage("success", "successfullUpdateAccount");
    } catch (error) {
      setLoading(false);
      AlertMessage("error", "unknownError");
    }
  }

  const handleVerifyUsername = async (value: string) => {
    try {
      const { data } = await axios.get<verifyUsernameResponse>(
        `${VERIFY_USERNAME}/${value}`
      );
      setIsAvailableUsername(data.available);
    } catch (err) {
      AlertMessage("error", "unknownError");
    }
  };

  const getData = async () => {
    try {
      const { data } = await axios.get("users/:id");
      reset({
        fullName: data.fullMame || "",
        phone: data.phoneDdi ? `+${data.phoneDdi}${data.phone}` : "",
        gender: data.gender || "Feminino",
        username: data.username || "",
      });
    } catch (err) {
      AlertMessage("error", "unknownError");
    }
  };

  useEffect(() => {
    if (debounced) {
      handleVerifyUsername(debounced);
    }
  }, [debounced]);

  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, [params]);

  return (
    <Box display="flex" flexDirection="column" gap={4} py={4}>
      <Box border="1px solid #DDDDDD" borderRadius={10} p={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="fullName"
              control={control}
              name={"fullName"}
              helperText={errors.fullName && errors.fullName.message}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PhoneInput
              label="phone"
              control={control}
              name={"phone"}
              helperText={errors.phone && errors.phone.message}
            />
          </Grid>

          <Grid item xs={12} md={6} mt={1}>
            <Select
              label="gender"
              control={control}
              name={"gender"}
              helperText={errors.gender && errors.gender.message}
              defaultValue={"Feminino"}
              options={[
                { label: t("female"), value: "Feminino" },
                { label: t("male"), value: "Masculino" },
                { label: t("other"), value: "Outro" },
                { label: t("pnd"), value: "Prefiro não dizer" },
              ]}
            />
          </Grid>
        </Grid>
      </Box>

      <Box border="1px solid #DDDDDD" borderRadius={10} p={4}>
        <Grid item xs={12}>
          <TextField
            label="userName"
            control={control}
            name="username"
            watch={(value) => setUsernameControl(value)}
          />
        </Grid>

        {(errors.username && errors.username.message) ||
        (!!usernameControl && !!debounced) ? (
          <Grid item xs={12} mt={2}>
            <Alert
              severity={
                !isAvailableUsername ||
                (errors.username && errors.username.message)
                  ? "error"
                  : "success"
              }
              sx={{ borderRadius: "20px", width: "auto" }}
            >
              {t(
                !isAvailableUsername ||
                  (errors.username && errors.username.message)
                  ? t(
                      errors.username && errors.username.message
                        ? `${errors.username.message}`
                        : "usernameUnavailable"
                    )
                  : t("usernameAvailable")
              )}
            </Alert>
          </Grid>
        ) : null}
      </Box>
      <Button
        onClick={handleSubmit(handleUpdateUser)}
        loading={loading}
        sx={{ maxWidth: 300, ml: "auto" }}
      >
        {t("updateData")}
      </Button>
    </Box>
  );
}
