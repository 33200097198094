import React, { useEffect } from 'react';

export const Index: React.FC = () => {
  useEffect(() => {
    if (location.pathname === '/') {
      const user = localStorage.getItem('user');
      if (user) {
        window.location.href = '/dashboard';
      } else {
        window.location.href = '/login';
      }
    }
  }, [location.pathname]);

  return <span />;
};
