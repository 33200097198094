import React, { useMemo } from "react";
import { useState } from "react";

import { Grid, Typography, Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";

import LoginImgWeb from "../../images/LoginImgWeb.png";
import LogoKahshAccounts from "../../images/LogoKahshAccounts.svg";

import axios from "axios";

import { LOGIN_URL } from "../../services/global";

import { useNavigate } from "react-router";

import { useAlert } from "../../hooks/alert";

import { Button } from "../../components/Button/Button";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { LanguageSelectorComponent } from "../../components/languageSelector/LanguageSelectorComponent";

const validator = yup.object().shape({
  email: yup.string().required("emailRequired").email("emailInvalid"),
  password: yup.string().required("passwordRequired"),
});

export function Login() {
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const returnUrl = useMemo(() => {
    let url = new URLSearchParams(window.location.search).get("returnUrl");

    if (url) {
      if (!url.includes("http")) {
        url = `https://${url}`;
      } else if (url.includes("http:")) {
        url = url.split("http://")[1];
        url = `https://${url}`;
      }
      //Entra apenas se estiver em https
      localStorage.setItem("returnUrl", url);
    } else {
      url = "";
      localStorage.removeItem("returnUrl");
    }
    return url;
  }, []);

  const handleLogin = async ({ email, password }: FieldValues) => {
    setLoading(true);

    try {
      let response;
      if (returnUrl) {
        response = await axios.post(LOGIN_URL, {
          email,
          password,
          returnUrl,
        });
      } else {
        response = await axios.post(LOGIN_URL, {
          email,
          password,
        });
      }

      setLoading(false);

      localStorage.setItem(
        "user",
        Buffer.from(
          JSON.stringify({
            email: response.data.email,
            name: response.data.name,
          })
        ).toString("base64")
      );

      localStorage.setItem(
        "token",
        Buffer.from(JSON.stringify(response.data.accessToken)).toString(
          "base64"
        )
      );

      if (returnUrl && response.data.remoteToken) {
        localStorage.removeItem("returnUrl");
        return (window.location.href = `${returnUrl}?remoteToken=${response.data.remoteToken}`);
      }

      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      return AlertMessage("error", "unknownError", error);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleLogin)();
    }
  };

  return (
    <Grid container spacing={2} minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          maxWidth={400}
          mx="auto"
          height="100%"
        >
          <Grid position="absolute" pb="40rem" ml="-100px">
            <LanguageSelectorComponent />
          </Grid>

          <img src={LogoKahshAccounts} alt="logo" />

          <Typography color="text.primary" py={5} variant="h5" mb={4} mt={2}>
            {t("loginTitle")}
          </Typography>

          <TextField
            sx={{ paddingBottom: 3 }}
            name="email"
            type="email"
            label="email"
            control={control}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.email && errors.email.message}
          />

          <TextField
            name="password"
            type="password"
            label="password"
            control={control}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.password && errors.password.message}
          />

          <Button
            tabIndex={-1}
            variant="text"
            onClick={() => navigate("/recovery-password")}
            sx={{ display: "flex", width: "auto", height: "auto", ml: "auto" }}
          >
            <Typography variant="caption">{t("forgotPassword")}</Typography>
          </Button>

          <Button
            loading={loading}
            onClick={handleSubmit(handleLogin)}
            sx={{ mt: 4 }}
          >
            {t("enter")}
          </Button>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="flex-end"
            width="100%"
          >
            <Typography variant="body2" color="text.secondary">
              {t("dontHaveAccount")}
            </Typography>
            <Button
              variant="text"
              onClick={() => {
                navigate(
                  `/register${
                    window.location.search ? window.location.search : ""
                  }`
                );
              }}
              sx={{ height: "auto", width: "auto" }}
            >
              <Typography variant="body2" fontWeight="bold">
                {t("signUp")}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          pt={4}
        >
          <img
            src={LoginImgWeb}
            alt="Recovery Password"
            width="100%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-40%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
