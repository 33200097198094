import { styled } from '@mui/material';
import Input from 'react-phone-number-input';
import { TextFieldStyled } from '../text-field/text-field.styles';

interface PhoneInputStyledProps {
  error: any;
}

export const PhoneInputStyled = styled(Input)<PhoneInputStyledProps>`
  width: 100%;

  .PhoneInputCountry {
    height: 56px;
    margin-left: 18px;
    -moz-user-focus: none;
  }
`;

interface PhoneTextFieldProps {
  value: string | undefined;
}

export const PhoneTextField = styled(TextFieldStyled)<PhoneTextFieldProps>`
  .MuiOutlinedInput-notchedOutline {
    margin-left: -59px;
  }

  .MuiInputLabel-root {
    margin-left: ${({ value }) => (value ? -59 : 0)}px;
    &.Mui-focused {
      margin-left: -59px;
    }
  }
  .MuiFormHelperText-root {
    margin-left: -47px;
  }
`;
