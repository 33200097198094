import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

import axios from "axios";

import { API_KEY } from "./services/global";

import App from "./App";

import { ThemeProvider } from "@mui/material";
import { AlertProvider } from "./hooks/alert";
import "./index.css";
import theme from "./theme";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN } from "./translate/en";
import { TRANSLATIONS_PT } from "./translate/pt";

axios.interceptors.request.use((req) => {
  if (req != null && req.headers != null) {
    req.headers["Content-Type"] = "application/json;charset=utf-8";
    req.headers["Access-Control-Allow-Origin"] = "*";
    req.headers["authorization"] = API_KEY;
  }
  return req;
});

axios.interceptors.response.use(
  function(success) {
    if (success) {
    }
    return success;
  },

  function(error: any) {
    if (error) {
      if (error.response.status === 400) {
        /*
         * testar os códigos internos
         */
        if (error.response.data.errorCode == 20) {
          window.location.href = "/emailVerify";
        }
      } else if (error.response.status === 401) {
        // window.location.href = "/login";
        // tomar ação para informar o usuário que não tem acesso a tela
      } else if (error.response.status === 403) {
        // window.location.href = "/login";
      }
    }
    throw error;
  }
);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      PTB: {
        translation: TRANSLATIONS_PT,
      },
      EN: {
        translation: TRANSLATIONS_EN,
      },
    },
    lng: "PTB",
  });

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AlertProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
