import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import axios from "axios";
import { VERIFY_EMAIL_URL } from "../../services/global";

import { useTranslation } from "react-i18next";

import LogoKahshAccounts from "../../images/LogoKahshAccounts.svg";
import Verified from "../../images/Verified.svg";
import EmailVerificadoComSucesso from "../../images/EmailVerificadoComSucesso.png";

import { useNavigate, useParams } from "react-router";
import { useAlert } from "../../hooks/alert";
import { Button } from "../../components/Button/Button";
import { LanguageSelectorComponent } from "../../components/languageSelector/LanguageSelectorComponent";

export function VerifyEmail() {
  const params = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { AlertMessage } = useAlert();

  const [seconds, setSeconds] = useState(5);

  const returnUrl = useMemo(() => {
    let url = localStorage.getItem("returnUrl");
    return url;
  }, []);

  const handleVerifyEmail = async (token: string) => {
    try {
      await axios.post(VERIFY_EMAIL_URL, {
        token,
        returnUrl: "https://kahsh.com",
      });
      AlertMessage("success", "verifiedEmail");
    } catch (error) {
      if (returnUrl) {
        navigate(`/login?returnUrl=${returnUrl}`);
      } else {
        navigate(`/login`);
      }
      AlertMessage("error", "unkownError", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (seconds >= 1) {
        setSeconds(seconds - 1);
      } else {
        if (returnUrl) {
          navigate(`/login?returnUrl=${returnUrl}`);
        } else {
          navigate(`/login`);
        }
      }
    }, 1000);
  }, [seconds]);

  useEffect(() => {
    if (params && params.token) {
      handleVerifyEmail(params.token);
    }
  }, [params.token]);

  return (
    <Grid container spacing={2} minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={400}
          mx="auto"
          height="100%"
        >
          <Grid position="absolute" pb="40rem" ml="-434px">
            <LanguageSelectorComponent />
          </Grid>

          <img src={LogoKahshAccounts} alt="logo" />

          <Typography color="text.primary" variant="h5" py={5} mb={4} mt={2}>
            {t("verifyEmailSuccess")}
          </Typography>

          <img src={Verified} alt="Verified" />

          <Typography variant="body2" color="text.primary" mb={3}>
            {t("successCreated")}
          </Typography>

          <Button
            onClick={() => {
              if (returnUrl) {
                navigate(`/login?returnUrl=${returnUrl}`);
              } else {
                navigate(`/login`);
              }
            }}
            sx={{ mt: 2 }}
          >
            {t("loginTitle")}
          </Button>
          <Typography variant="caption" color="text.primary">
            {t("autoLogin")} {seconds}s
          </Typography>
        </Box>
      </Grid>

      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          pt={4}
        >
          <img
            src={EmailVerificadoComSucesso}
            alt="Recovery Password"
            width="90%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-40%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
