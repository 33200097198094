export const TRANSLATIONS_PT = {
  currentCountry: "BR",
  dateFormat: "dd/MM/yyyy",
  loginTitle: "Faça seu login",
  enter: "Entrar",
  email: "Email",
  invite: "Convidar",
  password: "Senha",
  forgotPassword: "Esqueceu a senha?",
  dontHaveAccount: "Ainda não tem conta?",
  signUp: "Registre-se!",
  success: "Sucesso!",
  savePassword: "Salvar senha",
  updateData: "Atualizar dados",
  name: "Nome",
  userName: "Nome do usuário",
  logout: "Sair",
  back: "Voltar",
  referralLinkCopy:
    "Link de referência copiado com sucesso para área de transferência",
  backToStart: "Voltar ao início",
  // ====== verifyEmail translate
  verifyEmailSuccess: "Email verificado com sucesso",
  successCreated: "Sua conta foi criada com sucesso.",
  autoLogin: "Entrando no sistema em ",
  verifiedEmail: "Email verificado!",

  // ====== changePassword translate
  enterNewPassword: "Por favor insira sua nova senha",
  newPassword: "Nova senha",
  confirmPassword: "Confirme sua senha",
  save: "Salvar",
  oneUpperCase: "Deve conter ao menos uma letra maiúscula!",
  oneLowerCase: "Deve conter ao menos uma letra minúscula!",
  oneNumber: "Deve conter um número!",
  specialChar: "Deve conter um caractere especial!",
  eightDigits: "Deve possuir mais que 8 dígitos.",
  passwordsMatch: "As senhas não coincidem.",
  changePasswordSuccess: "Mudança de senha bem sucedida!",
  invalidToken: "Token inválido.",

  // ====== MENSAGEM DE ERRO  ======
  invalidEmail: "Email inválido",
  invalidName: "Nome inválido",
  "Emailalreadyused.": "Este Email já esta sendo utilizado.",
  "Usernotfound.": "Usuário ou senha inválidos.",
  "Accounthasbeentemporarilydisabledduetomanyfailedloginattempts.":
    "Muitas tentativas! Resete sua senha para logar imediatamente ou tente novamente mais tarde.",
  unknownError: "Erro desconhecido. Contate um administrador.",
  fillInAllFields: "Preencha todos os campos!",
  completeAllFields: "Preencha todos os campos corretamente.",

  // ====== RECUPERAR SENHA  ======
  recoverPassword: "Recuperar senha",
  checkYourSpamBox: "*Verifique sua caixa de spam",
  passwordSuccessReset:
    "Email encaminhado com sucesso, favor verificar sua caixa de email e spam",

  // ====== CADASTRO  ======
  cadastroTitle: "Faça seu cadastro",
  kshWalletAddress: "Endereço da carteira KSH",
  fullName: "Nome Completo",
  serviceTerms1: "Ao se inscrever, você concorda com os ",
  serviceTerms2: "Termos de Serviço",
  and: " e ",
  serviceTerms3: "Política de Privacidade do KahshPay.",
  alreadyHaveAccount: "Já tem uma conta? ",
  signIn: "Entrar",
  createAccount: "Criar conta",
  birthday: "Data de nascimento",
  phone: "Telefone",
  gender: "Gênero",
  female: "Feminino",
  male: "Masculino",
  other: "Outro",
  pnd: "Prefiro não dizer",
  successfullCreateAccount: "Conta criada com sucesso.",
  usernameAvailable: "Nome de usuário disponível",
  usernameUnavailable: "Nome de usuário indisponível",

  // ============= VALIDATORS REQUIRED=============
  required: "Obrigatório",
  nameRequired: "Nome é obrigatório",
  usernameRequired: "Nome de usuário é obrigatório",
  descriptionRequired: "Descrição é obrigatória",
  valueRequired: "Valor é obrigatório",
  amountRequired: "Quantidade é obrigatória",
  phoneRequired: "Telefone é obrigatório",
  emailRequired: "Email é obrigatório",
  documentRequired: "CPF/CNPJ é obrigatório",
  addressRequired: "Endereço é obrigatório",
  countryRequired: "País é obrigatório",
  segmentRequired: "Segmento é obrigatório",
  addressNumberRequired: "Numero é obrigatório",
  zipCodeRequired: "CEP é obrigatório",
  regionRequired: "Estado é obrigatório",
  cityRequired: "Cidade é obrigatória",
  DistrictRequired: "Bairro é obrigatório",
  passwordRequired: "Senha é obrigatória",
  confirmPasswordRequired: "Confirmação de senha é obrigatória",
  birthDateShopRequired: "Data de Fundação é obrigatória",
  birthDateRequired: "Data de Nascimento é obrigatória",
  totalValueRequired: "Valor Total é obrigatório",
  agreeRequired:
    "Você precisa aceitar os termos e politica de privacidade para prosseguir",

  // ============= VALIDATORS =============
  addressAutoCompleteInvalid: "O endereço selecionado não possui nome da rua",
  cpfInvalid: "CPF invalido!",
  cnpjInvalid: "CNPJ invalido!",
  usernameInvalid: "Nome de usuário invalido",
  zipCodeInvalid: "CEP invalido!",
  phoneInvalid: "Telefone invalido!",
  nameInvalid: "Nome deve conter 5 caracteres!",
  fullNameInvalid: "Nome incompleto!",
  emailInvalid: "Email inválido!",
  birthDateShopFuture: "Data de fundação não pode ser no futuro!",
  birthDateFuture: "Data de Nascimento não pode ser no futuro!",
  passwordEqualsError: "As senhas não conferem",
  youWereNominated: "Você foi indicado por ",
  toIntegrate: " para integrar ao ecossistema Kahsh!",
  enterYourEmail: "Digite seu email para recuperar a senha",
  enterYourEmailRecoverPassword: " Digite seu email para recuperar a senha",

  // ============= ERROR =============
  pageNotFound: "Página não encontrada!",
  pageNotFoundSubtitle: "A página que você está procurando não foi encontrada",
};
