import React, { useMemo } from "react";
import { useLocation } from "react-router";

import {
  Container,
  Typography,
  Box,
  Avatar,
  Popover,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";

import { Br, Us } from "react-flags-select";

import { Logout } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";

import Logo from "../../images/LogoKahshAccounts.svg";

import { useTranslation } from "react-i18next";

import { HeaderAppBar, HeaderLogo } from "./Header.styles";

import { useNavigate } from "react-router";

import { LanguageSelectorComponent } from "../languageSelector/LanguageSelectorComponent";

import "./Header.styles.css";

export const Header: React.FC = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const user = useMemo(() => {
    if (pathname !== "/" && pathname !== "/login") {
      if (localStorage.getItem("token")) {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          return JSON.parse(
            Buffer.from(storedUser, "base64").toString("ascii")
          );
        }
      }
    }
    return null;
  }, [pathname]);

  function handleOnClick(value: string) {
    i18n.changeLanguage(value);
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClickAvatar = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <HeaderAppBar position={"sticky"}>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <HeaderLogo
          src={Logo}
          alt={"e-diaristas"}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/login")}
        />
        <Box display="flex" alignItems="center" gap={1}>
          <LanguageSelectorComponent />

          {user && (
            <>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                p={1}
                borderRadius={2}
                onClick={handleClickAvatar}
                sx={{ cursor: "pointer", "&:hover": { background: "#fafafa" } }}
              >
                <Avatar
                  variant="rounded"
                  sx={{ bgcolor: (theme) => theme.palette.grey[300] }}
                >
                  <PersonIcon />
                </Avatar>

                <Box display={{ xs: "none", md: "block" }}>
                  <Typography variant="caption" color="text.secondary">
                    {user.email}
                  </Typography>

                  <Typography
                    variant="caption"
                    component="p"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {user.name}
                  </Typography>
                </Box>
              </Box>
              <Menu
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="logoutMenu"
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t("logout")}
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Container>
    </HeaderAppBar>
  );
};
