import React from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Cadastro } from "./pages/cadastro/Cadastro";
import Dashboard from "./pages/dashboard/Dashboard";
import { Login } from "./pages/login/Login";
import { ChangePassword } from "./pages/recuperarSenha/ChangePassword";
import { RecuperarSenha } from "./pages/recuperarSenha/RecuperarSenha";
import { VerifyEmail } from "./pages/verifyEmail/VerifyEmail";

import { Box, Container } from "@mui/material";
import { useAlert } from "./hooks/alert";
import { EditProfile } from "./pages/editProfile/EditProfile";

import "./App.css";
import { Header } from "./components/header/Header";
import { Index } from "./pages";
import { NotFound } from "./pages/notFound/NotFound";
import { BackgroundBar } from "./styles/BackgroundBar";

function App() {
  const { Alert } = useAlert();
  let location = useLocation();

  const hasBar = (): boolean => {
    const hasBarRoutes = ["/login", "/register", "/verify", "/recovery"];

    if (window.location.href) {
      return hasBarRoutes.some((route) => window.location.href.includes(route));
    }
    return false;
  };
  let backheader = hasBar();

  useEffect(() => {
    backheader = hasBar();
  }, [location]);

  return (
    <Box position="relative">
      {backheader ? <BackgroundBar /> : <Header />}
      <Container maxWidth="xl">
        {Alert && <Alert />}
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Cadastro />} />
          <Route path="/recovery-password" element={<RecuperarSenha />} />
          <Route path="/verify/:token" element={<VerifyEmail />} />
          <Route path="/recovery/:token" element={<ChangePassword />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </Box>
  );
}
export default App;
