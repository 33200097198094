import React from "react";
import { useNavigate } from "react-router";

import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import NotFoundImage from "../../images/error/ErrorNotFound.svg";

import { Button } from "../../components/Button/Button";

export const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <img src={NotFoundImage} alt={t("notFoundTitle")} />

      <Grid pt="40rem" textAlign="center" position="absolute">
        <Typography variant="h2" color="primary.main" fontWeight="bold">
          {t("pageNotFound")}
        </Typography>

        <Typography pt={1} pb={5} variant="h5" color="primary.main">
          {t("pageNotFoundSubtitle")}
        </Typography>

        <Button
          onClick={() => {
            navigate("/login");
          }}
          sx={{ height: "auto", width: "auto" }}
        >
          {t("backToStart")}
        </Button>
      </Grid>
    </Grid>
  );
};
