export const TRANSLATIONS_EN = {
  currentCountry: "US",
  dateFormat: "MM/dd/yyyy",
  loginTitle: "Login",
  email: "Email",
  invite: "Invite",
  password: "Password",
  enter: "Enter",
  forgotPassword: "Forgot Password?",
  dontHaveAccount: "Don`t have an account yet?",
  signUp: "Sign up!",
  success: "Success!",
  savePassword: "Save password",
  updateData: "Update data",
  name: "Name",
  userName: "Username",
  logout: "Logout",
  back: "Back",
  referralLinkCopy: "Referral link successfully copied to clipboard",
  backToStart: "Back to start",
  // ====== verifyEmail translate
  verifyEmailSuccess: "Email successfully verified",
  successCreated: "Your account has been successfully created.",
  autoLogin: "Logging in on ",
  verifiedEmail: "Email verified!",

  // ====== changePassword translate
  enterNewPassword: "Please enter new password",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  save: "Save",
  oneUpperCase: "Must contain at least one upper case!",
  oneLowerCase: "Must contain at least one lower case!",
  oneNumber: "Must contain a number!",
  eightDigits: "Must contain at least 8 digits.",
  passwordsMatch: "Passwords does not match.",
  changePasswordSuccess: "You changed your password successfully!",
  invalidToken: "Invalid token.",

  // ====== MENSAGEM DE ERRO  ======

  invalidEmail: "Invalid Email",
  invalidName: "Invalid name",
  "Emailalreadyused.": "Email already used.",
  invalidPassword: "Invalid Password.",
  manyAttempts:
    "Many attempts! Reset your password to log in immediately or try again later.",
  unknownError: "Unknown error. Contact an administrator.",
  fillInAllFields: "Fill in all fields!",
  completeAllFields: "Complete all the fields correctly.",

  // ====== RECUPERAR SENHA  ======
  recoverPassword: "Recover password",
  checkYourSpamBox: "*Check your spam box",
  passwordSuccessReset:
    "Email successfully forwarded, check your inbox and spam",

  // ====== CADASTRO  ======
  cadastroTitle: "Make your registration",
  kshWalletAddress: "KSH Wallet Address",
  fullName: "Full name",
  serviceTerms1: "By signing up you agree to the KahshPay ",
  serviceTerms2: "Terms of Service",
  and: " & ",
  serviceTerms3: " Privacy Policy.",
  alreadyHaveAccount: "Already have an account? ",
  signIn: "Sign In.",
  createAccount: "Create Account",
  birthday: "Birthday",
  phone: "Phone",
  gender: "Gender",
  female: "Female",
  male: "Male",
  other: "Other",
  pnd: "Rather not say",
  specialChar: "Must contain at least special char!",
  "Usernotfound.": "Invalid user or password.",
  "Accounthasbeentemporarilydisabledduetomanyfailedloginattempts.":
    "Many attempts! Reset your password to log in immediately or try again later.",
  successfullCreateAccount: "Account was successfully created.",
  usernameAvailable: "Username available",
  usernameUnavailable: "Username unavailable",

  // ============= VALIDATORS REQUIRED=============
  required: "Required",
  descriptionRequired: "Description is required",
  valueRequired: "Value is required",
  amountRequired: "Amount is required",
  nameRequired: "Name is required",
  usernameRequired: "Username is required",
  phoneRequired: "Phone number is required",
  emailRequired: "Email is required",
  documentRequired: "CPF/CNPJ is required",
  addressRequired: "Address is required",
  countryRequired: "Country is required",
  segmentRequired: "Segment is required",
  addressNumberRequired: "Address number is required",
  zipCodeRequired: "Zip code is required",
  regionRequired: "State is required",
  cityRequired: "City is required",
  DistrictRequired: "District is required",
  passwordRequired: "Password is required",
  confirmPasswordRequired: "Password confirmation is required",
  birthDateShopRequired: "Founding date is required",
  agreeRequired: "You must accept the terms and privacy policy to continue.",
  birthDateRequired: "Birth date is required",
  totalValueRequired: "Total Value is required",

  // ============= VALIDATORS =============
  addressAutoCompleteInvalid: "The selected address has no street name",
  cpfInvalid: "Invalid CPF",
  cnpjInvalid: "Invalid CNPJ",
  zipCodeInvalid: "Invalid zip code",
  phoneInvalid: "Invalid phone number",
  fullNameInvalid: "Incomplete name",
  usernameInvalid: "Invalid username",
  emailInvalid: "Invalid email",
  birthDateShopFuture: "Founding date cannot be in the future",
  birthDateFuture: "Birth date cannot be in the future",
  passwordEqualsError: "Password do not match",
  nameInvalid: "Name must be 5 characters long!",
  youWereNominated: "You were nominated by ",
  toIntegrate: " to integrate into the Kahsh ecosystem!",
  enterYourEmail: "Enter your email to recover password",
  enterYourEmailRecoverPassword: "Enter your email to recover password",
  // ============= ERROR =============
  pageNotFound: "Page not found!",
  pageNotFoundSubtitle: "the page you are looking for was not found",
};
