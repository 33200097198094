import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import Settings from '../../images/Settings.png';
import Logout from '../../images/Logout.png';
import KahshLogo from '../../images/KahshLogo.png';
import DashboardInvite from '../../images/Dashboard-invite.png';

import { useAlert } from '../../hooks/alert';
import { Button } from '../../components/Button/Button';
interface IItems {
  id: number;
  logo: string;
  header?: JSX.Element | string;
  body: JSX.Element | string;
  size?: number;
  button: string;
  click?: VoidFunction;
}

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const { AlertMessage } = useAlert();
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const dashboardItems: Array<IItems> = [
    {
      id: 0,
      logo: KahshLogo,
      body: (
        <>
          <span style={{ color: '#1C1439', fontWeight: 700, fontSize: 30 }}>
            kahsh
          </span>
          <span style={{ fontSize: 30 }}>pay</span>
        </>
      ),
      button: t('enter')
    },
    {
      id: 1,
      logo: KahshLogo,
      header: (
        <>
          <span style={{ color: '#141010', fontWeight: 700, fontSize: 45 }}>
            kahsh
          </span>
        </>
      ),
      body: 'Exchange',
      button: t('enter')
    },
    {
      id: 2,
      logo: KahshLogo,
      header: (
        <>
          <span style={{ color: '#141010', fontWeight: 700, fontSize: 45 }}>
            kahsh
          </span>
        </>
      ),
      body: 'Exchange',
      button: t('enter')
    },
    {
      id: 3,
      logo: DashboardInvite,
      header: '',
      body:
        i18n.language.substring(0, 3) == 'PTB' ? (
          <>
            <span>Convide um amigo para </span>
            <span style={{ color: '#1C1439', fontWeight: 700 }}>
              conhecer a Kahsh
            </span>
          </>
        ) : (
          <>
            <span>Invite a friend to </span>
            <span style={{ color: '#1C1439', fontWeight: 700 }}>
              meet Kahsh
            </span>
          </>
        ),
      size: 16,
      button: t('invite'),
      click: () => {
        navigator.clipboard.writeText(
          `${window.location.origin}/register?email=${email}`
        );
        AlertMessage('success', 'referralLinkCopy');
      }
    }
  ];

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setEmail(
        JSON.parse(Buffer.from(storedUser, 'base64').toString('ascii')).email
      );
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <Grid container spacing={4} minHeight="calc(100vh - 61.8px)">
      <Grid
        item
        xs={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        display={{ xs: 'none', md: 'flex' }}
        gap={4}
      >
        <img src={Settings} alt="" width={24} />
        <img src={Logout} alt="" width={24} onClick={handleLogout} />
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={11}
        flexWrap="wrap"
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'space-evenly' }}
        mt={6}
        gap={4}
      >
        {dashboardItems.map((e: IItems, index: number) => (
          <Grid
            key={e.id}
            item
            container
            height={330}
            maxWidth={250}
            borderRadius={7}
            border={'1px solid #4733FF'}
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            textAlign="center"
            sx={{
              cursor: 'pointer',
              boxShadow: '0px 3px 5px 0px #000000'
            }}
            mb={{ xs: 10, md: 0 }}
          >
            <Grid pt={6}>
              <img
                src={e.logo}
                width={index !== 3 ? 100 : 150}
                height={index !== 3 ? 100 : 150}
              />
              <Typography>{e.header}</Typography>
              <Typography fontSize={20}>{e.body}</Typography>
            </Grid>
            <Grid container justifyContent="center" mb={-2}>
              <Button size="small" sx={{ width: 'auto' }} onClick={e.click}>
                {e.button}
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
