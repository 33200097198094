import React from "react";

import { Typography, MenuItem } from "@mui/material";

import { Br, Us } from "react-flags-select";

import { useTranslation } from "react-i18next";

import { LanguageSelector } from "../header/Header.styles";

export const LanguageSelectorComponent = () => {
  const { i18n } = useTranslation();

  const languages = [
    {
      langText: "Português (BR)",
      value: "PTB",
      flag: <Br fontSize="1.8rem" />,
    },
    {
      langText: "English (US)",
      value: "EN",
      flag: <Us fontSize="1.8rem" />,
    },
  ];

  function handleOnClick(value: string) {
    i18n.changeLanguage(value);
  }

  return (
    <LanguageSelector
      className="translateSelect"
      labelId="translate"
      id="translate"
      defaultValue={i18n.language.toUpperCase().includes("PT") ? "PTB" : "EN"}
      MenuProps={{
        disableScrollLock: true,
      }}
    >
      {languages.map((item) => (
        <MenuItem
          key={item.value}
          className="translate"
          value={item.value}
          onClick={() => handleOnClick(item.value)}
        >
          {item.flag}
          <Typography
            pl={1}
            fontSize="1rem"
            fontFamily="Montserrat"
            fontWeight={700}
            color="#838383"
          >
            {item.langText}
          </Typography>
        </MenuItem>
      ))}
    </LanguageSelector>
  );
};
