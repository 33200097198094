import React, { useMemo } from "react";
import { useState } from "react";

import { Alert, Grid, Typography, Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import CadeadoWeb from "../../images/CadeadoWeb.svg";
import LogoKahshAccounts from "../../images/LogoKahshAccounts.svg";
import RecuperarSenhaImgWeb from "../../images/RecuperarSenhaImgWeb.png";

import axios from "axios";

import { RECOVERY_PASSWORD_URL } from "../../services/global";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAlert } from "../../hooks/alert";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { Button } from "../../components/Button/Button";
import { FieldValues, useForm } from "react-hook-form";
import { LanguageSelectorComponent } from "../../components/languageSelector/LanguageSelectorComponent";

const validator = yup.object().shape({
  email: yup.string().email("emailInvalid").required("emailRequired"),
});

export function RecuperarSenha() {
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();

  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const returnUrl = useMemo(() => {
    let url = localStorage.getItem("returnUrl");
    return url;
  }, []);

  const handleRecovery = async ({ email }: FieldValues) => {
    setIsLoading(true);
    try {
      await axios.post(RECOVERY_PASSWORD_URL, {
        email,
      });
      setIsLoading(false);
      setSuccessMessage("passwordSuccessReset");
    } catch (error) {
      setIsLoading(false);
      AlertMessage("error", "unknownError", error);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleRecovery)();
    }
  };

  return (
    <Grid container spacing={2} minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={400}
          mx="auto"
          height="100%"
        >
          <Grid position="absolute" pb="40rem" ml="-434px">
            <LanguageSelectorComponent />
          </Grid>

          <img src={LogoKahshAccounts} alt="logo" />

          <Typography color="text.primary" py={5} variant="h5" mt={2}>
            {t("recoverPassword")}
          </Typography>

          <img src={CadeadoWeb} alt={"Cadeado"} width="120px" />

          <Typography variant="body2" color="text.primary" mb={2}>
            {t("enterYourEmail")}
          </Typography>

          <Grid container spacing={2} maxWidth={400} direction="row-reverse">
            <Grid item xs={12} pb={3}>
              {!!successMessage && (
                <Alert severity="success" sx={{ mb: 2, borderRadius: "20px" }}>
                  {t(successMessage)}
                </Alert>
              )}
              <TextField
                name="email"
                label="email"
                control={control}
                onKeyDown={(e) => handleKeyboardEvent(e)}
                helperText={errors.email && errors.email.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                loading={loading}
                disabled={loading}
                onClick={handleSubmit(handleRecovery)}
              >
                {t("recoverPassword")}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                // onClick={() => {
                //   if (returnUrl) {
                //     navigate(`/login?returnUrl=${returnUrl}`);
                //   } else {
                //     navigate(`/login`);
                //   }
                // }}
              >
                {t("back")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          pt={4}
        >
          <img
            src={RecuperarSenhaImgWeb}
            alt="Recovery Password"
            width="100%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-40%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
