import { createTheme } from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#4733FF",
    },
    text: {
      primary: "#001C57",
      secondary: "#828282",
    },
    error: {
      main: "#ff0000",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontFamily: "Montserrat",
        },
      },
      variants: [
        {
          props: { variant: "body1" },
          style: {
            fontSize: "1.25rem",
          },
        },
        {
          props: { variant: "body2" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "h6" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "caption" },
          style: {
            fontSize: "0.9rem",
          },
        },
        {
          props: { variant: "subtitle1" },
          style: {
            fontSize: "13px",
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#B4B4C1",
          backgroundColor: "#FDFDFF",
          borderRadius: "20px",
          zIndex: "-1",
        },
        input: {
          borderRadius: "20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            color: "#000",
            borderColor: "#000",
            "&:hover": { borderColor: "#000" },
          },
        },
      ],
    },
  },
});
export default theme;
