//url de DEV
// export const BASE_URL = 'https://api-accounts-sandbox.kahsh.com';
// export const API_KEY = '2b7cievfoi8zw7v9';

//url de PROD
export const BASE_URL = "https://api-accounts.kahsh.com"
export const API_KEY = "hn22nrs1q9ri8ubo"

export const LOGIN_URL = BASE_URL + '/login';
export const LOGIN_VERIFY_URL = BASE_URL + '/login/verify';
export const RECOVERY_PASSWORD_URL = BASE_URL + '/recoverypassword';
export const RECOVERY_PASSWORD_REDEFINE_URL =
  BASE_URL + '/recoverypassword/redefine';
export const RECOVERY_PASSWORD_VERIFY_URL =
  BASE_URL + '/recoverypassword/verify';
export const RESET_PASSWORD_URL = BASE_URL + '/resetpassword';
export const REGISTER_URL = BASE_URL + '/register';
export const VERIFY_SEND_EMAIL_URL = BASE_URL + '/verifyemail';
export const VERIFY_EMAIL_URL = BASE_URL + '/verifyemail/verify';
export const VERIFY_INVITE = BASE_URL + '/get';
export const VERIFY_USERNAME = BASE_URL + '/register/verifyusername';
