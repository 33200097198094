import React, { useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { useForm, FieldValues, useController } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import {
  TextFieldStyledProps,
  TextField,
} from "../text-field/text-field.component";
import { TextFieldStyled } from "../text-field/text-field.styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

interface SelectProps extends TextFieldStyledProps {
  options: { value: any; label: string }[];
  addButton?: boolean;
  newOptionDefaultValue?: string;
  required?: boolean;
  size?: "medium" | "small";
}

const validator = yup.object().shape({
  newOption: yup.string().required("required"),
});

export const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  control,
  helperText = "",
  name,
  label,
  placeholder,
  options,
  newOptionDefaultValue,
  defaultValue,
  required,
  addButton,
  watch,
  size = "small",
  ...rest
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newOption, setNewOption] = useState(newOptionDefaultValue || "");
  const { t } = useTranslation();

  const {
    control: addControl,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const { field } = useController({
    name: name || "",
    control: control || undefined,
    defaultValue: defaultValue || "",
  });

  const handleAddClick = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    reset();
  };

  const handleSubmitNewItem = (data: FieldValues) => {
    setNewOption(data.newOption);
    handleClose();
  };

  return (
    <>
      <TextFieldStyled
        select
        size={size}
        error={!!helperText}
        label={!!label && t(label.toString())}
        helperText={!!helperText && t(helperText.toString())}
        name={field.name}
        value={field.value}
        onChange={(event) => {
          watch && watch(event.target.value);
          field.onChange(event);
        }}
        fullWidth
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {addButton && !newOption && (
          <MenuItem>
            <Button
              variant="outlined"
              onClick={handleAddClick}
              fullWidth
              startIcon={<AddIcon />}
            >
              Adicionar opção
            </Button>
          </MenuItem>
        )}
        {addButton && newOption && (
          <MenuItem value={newOption}>{newOption}</MenuItem>
        )}
      </TextFieldStyled>
      {addButton && (
        <Dialog disableEscapeKeyDown open={openDialog} onClose={handleClose}>
          <DialogTitle>{t("addOption")}</DialogTitle>
          <DialogContent>
            <Box p={2}>
              <TextField
                label="addOption"
                control={addControl}
                helperText={
                  errors.newOption && (errors.newOption.message as any)
                }
                name="newOption"
                required
                defaultValue={newOptionDefaultValue || ""}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": { zIndex: 0 },
                  ".MuiOutlinedInput-input": { zIndex: 1 },
                  ".MuiInputLabel-root": { zIndex: 2 },
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("cancel")}</Button>
            <Button onClick={handleSubmit(handleSubmitNewItem)}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
