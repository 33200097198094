import React, { useEffect, useMemo, useState } from "react";

import { Grid, Typography, Link, Box } from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, FieldValues } from "react-hook-form";

import { useTranslation } from "react-i18next";

import LogoKahshAccounts from "../../images/LogoKahshAccounts.svg";
import FacaSeuCadastroImgWeb from "../../images/FacaSeuCadastroImgWeb.png";

import axios from "axios";

import { LOGIN_URL, REGISTER_URL, VERIFY_INVITE } from "../../services/global";

import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

import { useNavigate } from "react-router";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { useAlert } from "../../hooks/alert";
import { Button } from "../../components/Button/Button";
import { PhoneInput } from "../../components/inputs/phoneInput/PhoneInput";
import { Checkbox } from "../../components/inputs/checkbox/checkbox.component";
import { LanguageSelectorComponent } from "../../components/languageSelector/LanguageSelectorComponent";

interface InviteResponse {
  kahshId: string;
  email: string;
  name: string;
  username: string;
}

interface ILoginProps {
  email: string;
  password: string;
  returnUrl?: string;
}

const validator = yup.object().shape({
  fullName: yup
    .string()
    .required("nameRequired")
    .min(5, "nameInvalid"),
  phone: yup
    .string()
    .required("phoneRequired")
    .test(`test-password`, function(value) {
      const { path, createError } = this;
      return (
        isValidPhoneNumber(`${value}`) ||
        createError({ path, message: "phoneInvalid" })
      );
    }),
  email: yup
    .string()
    .email("emailInvalid")
    .required("emailRequired"),
  password: yup
    .string()
    .required("passwordRequired")
    .test(`test-password`, function(value) {
      const { path, createError } = this;
      if (!value) return false;
      const errors: string[] = [];
      if (!/(?=.*[a-z])/.test(value)) errors.push("oneLowerCase");
      if (!/(?=.*[A-Z])/.test(value)) errors.push("oneUpperCase");
      if (!/(?=.*[0-9])/.test(value)) errors.push("oneNumber");
      if (!/(?=.*[!@#$%^&*_)(-])/.test(value)) errors.push("specialChar");
      if (!/(?=.{8,})/.test(value)) errors.push("eightDigits");
      return (
        !errors.length || createError({ path, message: errors.join("\n") })
      );
    }),
  confirmPassword: yup
    .string()
    .required("confirmPasswordRequired")
    .oneOf([yup.ref("password"), null], "passwordEqualsError"),
  agree: yup
    .boolean()
    .default(false)
    .oneOf([true], "agreeRequired"),
});

export function Cadastro() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { AlertMessage } = useAlert();

  const [nameIndication, setNameIndication] = useState("");
  const [kahshId, setKahshId] = useState("");
  const [nameIndicationValue, setNameIndicationValue] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validator),
  });

  const returnUrl = useMemo(() => {
    let url =
      localStorage.getItem("returnUrl") ||
      new URLSearchParams(window.location.search).get("returnUrl");
    if (url) {
      if (!url.includes("http")) {
        url = `https://${url}`;
      } else if (url.includes("http:")) {
        url = url.split("http://")[1];
        url = `https://${url}`;
      }
    }
    return url;
  }, []);

  async function handleRegister(data: FieldValues) {
    setLoading(true);
    try {
      const parsedPhone = parsePhoneNumber(data.phone);
      if (parsedPhone) {
        data.phoneDdi = parsedPhone.countryCallingCode;
        data.phone = parsedPhone.nationalNumber;
      }
      if (nameIndication) {
        data.referral = kahshId;
      }

      const { data: response } = await axios.post(REGISTER_URL, data);
      setLoading(false);
      localStorage.setItem(
        "user",
        Buffer.from(
          JSON.stringify({ email: response.email, name: response.name })
        ).toString("base64")
      );
      AlertMessage("success", "successfullCreateAccount");

      let loginData: ILoginProps = {
        email: data.email,
        password: data.password,
      };
      if (returnUrl) {
        loginData.returnUrl = returnUrl;
      }
      let responseLogin = await axios.post(LOGIN_URL, loginData);
      localStorage.removeItem("returnUrl");
      localStorage.setItem(
        "token",
        Buffer.from(JSON.stringify(responseLogin.data.accessToken)).toString(
          "base64"
        )
      );
      if (returnUrl) {
        return (window.location.href = `${returnUrl}?remoteToken=${responseLogin.data.remoteToken}`);
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const handleSearchInvite = async (query: string) => {
    try {
      const { data } = await axios.get<InviteResponse>(
        `${VERIFY_INVITE}?${query}`
      );
      setKahshId(data.kahshId)
      setNameIndicationValue((data && data.username) || (data && data.email));
    } catch (err) {
      AlertMessage("error", "unknownError");
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleRegister)();
    }
  };

  useEffect(() => {
    const kahshId = new URLSearchParams(window.location.search).get("kahshId");
    const username = new URLSearchParams(window.location.search).get(
      "username"
    );
    const email = new URLSearchParams(window.location.search).get("email");

    let query = "";

    if (kahshId) {
      query = `kahshId=${kahshId}`;
    } else if (username) {
      query = `username=${username}`;
    } else if (email) {
      query = `email=${email}`;
    }

    if (query) {
      handleSearchInvite(query);
      setNameIndication(`${kahshId || username || email}`);
    }
  }, []);

  return (
    <Grid container spacing={2} minHeight="100vh">
      <Grid item xs={12} md={6} height="100%" my="auto">
        <LanguageSelectorComponent />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          maxWidth={400}
          mx="auto"
          height="100%"
          gap={2}
          pt={4}
        >
          <img src={LogoKahshAccounts} alt="logo" />

          {!!nameIndicationValue ? (
            <Typography variant="body2" color="primary.main" mt={2}>
              {t("youWereNominated")}
              <strong>{nameIndicationValue}</strong>
              {t("toIntegrate")}
            </Typography>
          ) : null}

          <Typography variant="h5" color="text.primary" mb={2} mt={2}>
            {t("cadastroTitle")}
          </Typography>

          <TextField
            label="fullName"
            control={control}
            name={"fullName"}
            onKeyUp={(e) => handleKeyboardEvent(e)}
            helperText={errors.fullName && errors.fullName.message}
          />

          <PhoneInput
            tabIndex={-1}
            label="phone"
            control={control}
            name={"phone"}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.phone && errors.phone.message}
          />

          <TextField
            label="gender"
            control={control}
            name={"gender"}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.gender && errors.gender.message}
          />

          <TextField
            label="email"
            control={control}
            name={"email"}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.email && errors.email.message}
          />

          <TextField
            label="password"
            control={control}
            name={"password"}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.password && errors.password.message}
            type="password"
          />

          <TextField
            label="confirmPassword"
            control={control}
            name={"confirmPassword"}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
            type="password"
          />

          <Checkbox
            size="small"
            name="agree"
            control={control}
            error={errors.agree && errors.agree.message}
            label={
              <Typography color="text.secondary" variant="caption">
                {t("serviceTerms1")}
                <Link
                  tabIndex={-1}
                  target="_blank"
                  href="https://exchange.kahsh.com/termosdeuso"
                >
                  {t("serviceTerms2")}
                </Link>
                {t("and")}
                <Link
                  tabIndex={-1}
                  target="_blank"
                  href="https://exchange.kahsh.com/politicadeprivacidade"
                >
                  {t("serviceTerms3")}
                </Link>
              </Typography>
            }
          />
          <Box width="100%">
            <Button loading={loading} onClick={handleSubmit(handleRegister)}>
              {t("createAccount")}
            </Button>

            <Box
              display="flex"
              alignItems="center"
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="center"
              width="100%"
            >
              <Typography variant="body2" color="text.secondary">
                {t("alreadyHaveAccount")}
              </Typography>
              <Button
                tabIndex={-1}
                variant="text"
                onClick={() => {
                  if (returnUrl) {
                    navigate(`/login?returnUrl=${returnUrl}`);
                  } else {
                    navigate(`/login`);
                  }
                }}
                sx={{ height: "auto", width: "auto" }}
              >
                <Typography variant="body2" fontWeight="bold">
                  {t("signIn")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          pt={4}
        >
          <img
            src={FacaSeuCadastroImgWeb}
            alt="Recovery Password"
            width="100%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-40%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
