import React, { useEffect, useMemo, useState } from "react";
import { Grid, Skeleton, Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";

import {
  RECOVERY_PASSWORD_REDEFINE_URL,
  RECOVERY_PASSWORD_VERIFY_URL,
} from "../../services/global";

import { useTranslation } from "react-i18next";

import LogoKahshAccounts from "../../images/LogoKahshAccounts.svg";
import CadeadoWeb from "../../images/CadeadoWeb.svg";
import NovaSenhaWeb from "../../images/NovaSenhaWeb.png";

import { useAlert } from "../../hooks/alert";
import { Button } from "../../components/Button/Button";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { LanguageSelectorComponent } from "../../components/languageSelector/LanguageSelectorComponent";

const validator = yup.object().shape({
  newPassword: yup
    .string()
    .required("passwordRequired")
    .test(`test-password`, function (value) {
      const { path, createError } = this;
      if (!value) return false;
      const errors: string[] = [];
      if (!/(?=.*[a-z])/.test(value)) errors.push("oneLowerCase");
      if (!/(?=.*[A-Z])/.test(value)) errors.push("oneUpperCase");
      if (!/(?=.*[0-9])/.test(value)) errors.push("oneNumber");
      if (!/(?=.*[!@#$%^&*_)(-])/.test(value)) errors.push("specialChar");
      if (!/(?=.{8,})/.test(value)) errors.push("eightDigits");
      return (
        !errors.length || createError({ path, message: errors.join("\n") })
      );
    }),
  confirmPassword: yup
    .string()
    .required("confirmPasswordRequired")
    .oneOf([yup.ref("newPassword"), null], "passwordEqualsError"),
});

export function ChangePassword() {
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const returnUrl = useMemo(() => {
    let url = localStorage.getItem("returnUrl");
    return url;
  }, []);

  const validateToken = async () => {
    try {
      await axios.get(RECOVERY_PASSWORD_VERIFY_URL, {
        params: { token: params.token },
      });
      setLoadingData(false);
    } catch (e) {
      navigate("/login");
      setLoadingData(false);
      AlertMessage("error", "invalidToken", e);
    }
  };

  const handleUpdatePassword = async ({ newPassword }: FieldValues) => {
    setLoading(true);
    try {
      await axios.post(RECOVERY_PASSWORD_REDEFINE_URL, {
        token: params.token,
        newPassword,
        returnUrl: "https://kahsh.com",
      });
      setLoading(false);
      AlertMessage("success", "changePasswordSuccess");
      if (returnUrl) {
        navigate(`/login?returnUrl=${returnUrl}`);
      } else {
        navigate("/login");
      }
    } catch (error) {
      setLoading(false);
      AlertMessage("error", "unknownError", error);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleUpdatePassword)();
    }
  };

  useEffect(() => {
    if (params.token) {
      validateToken();
    }
  }, [params.token]);

  return (
    <Grid container spacing={2} minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={400}
          mx="auto"
          height="100%"
        >
          <Grid position="absolute" pb="40rem" ml="-434px">
            <LanguageSelectorComponent />
          </Grid>

          <img src={LogoKahshAccounts} alt="logo" />

          <Typography color="text.primary" variant="h5" mb={4} mt={2}>
            {t("recoverPassword")}
          </Typography>

          <img src={CadeadoWeb} alt={"Cadeado"} width="120px" />

          <Typography variant="body2" color="text.primary" mb={2}>
            {t("enterYourEmailRecoverPassword")}
          </Typography>

          <Grid container spacing={2} direction="row-reverse">
            {loadingData ? (
              <>
                <Skeleton
                  width={400}
                  height={60}
                  sx={{ borderRadius: "20px" }}
                />
                <Skeleton
                  width={400}
                  height={60}
                  sx={{ borderRadius: "20px" }}
                />
                <Skeleton
                  width={400}
                  height={60}
                  sx={{ borderRadius: "20px" }}
                />
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="newPassword"
                    label="password"
                    control={control}
                    helperText={
                      errors.newPassword && errors.newPassword.message
                    }
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="confirmPassword"
                    label="confirmPassword"
                    control={control}
                    onKeyDown={(e) => handleKeyboardEvent(e)}
                    helperText={
                      errors.confirmPassword && errors.confirmPassword.message
                    }
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    loading={loading}
                    onClick={handleSubmit(handleUpdatePassword)}
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>

      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          pt={4}
        >
          <img
            src={NovaSenhaWeb}
            alt="Recovery Password"
            width="100%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-40%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
