import { styled } from '@mui/material/styles';
import { AppBar, Avatar, Select } from '@mui/material';

export const HeaderAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
  }
`;

export const HeaderLogo = styled('img')`
  height: 36px;
`;

export const LanguageSelector = styled(Select)`
  .MuiOutlinedInput-notchedOutline{
    border-width: 0 !important;
  }

  .MuiSelect-select{
    display:flex;
    align-items: center;

    p {
      display: none
    }
  }
`;

export const AvatarStyled = styled(Avatar)`
  width: 30px;
  height: 30px;
`;