import React from "react";

import {
  Alert,
  Slide,
  Stack,
  AlertColor,
  IconButton,
  AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type AlertComponentProps = {
  severity: AlertColor;
  message: string;
  setOpen: any;
  open: boolean;
  title?: string;
  onClick?: any;
  timeout?: number;
};

export function AlertComponent(props: AlertComponentProps) {
  if (props.timeout) {
    setTimeout(() => {
      props.setOpen(false);
    }, props.timeout);
  } else {
    setTimeout(() => {
      props.setOpen(false);
    }, 5000);
  }

  return (
    <Slide in={props.open} timeout={250} direction="left" mountOnEnter>
      <Stack
        id="alertComponent"
        sx={{
          maxWidth: "20em",
          width: "calc(100% - 2em)",
          position: "fixed",
          top: "4em",
          right: "1em",
          zIndex: 99999,
        }}
        spacing={2}
      >
        <Alert
          severity={props.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => props.setOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.title ? <AlertTitle>{props.title}</AlertTitle> : null}
          {props.message}
        </Alert>
      </Stack>
    </Slide>
  );
}

export default AlertComponent;
